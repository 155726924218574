import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import HeatMap from "../views/HeatMap/HeatMap";
import InspectContentTable from "./InspectContentTable";
import InspectFilter from "./InspectFilter";
import InspectViewSwitch from "./InspectViewSwitch";
import { isEmpty, isNull } from "lodash";
import Page_Loader from "../../assets/vizr_images/page_loader.gif";

import {
  CLEAR_ALL_NODE_ID,
  ALL_FILES_LIST,
  FETCH_ALL_FIELDS_DATA_REQUEST,
  FETCH_SENSITIVE_DATA_FIELDS_REQUEST,
  CLEAR_INSPECT_FILTER_DATA,
  CLOSE_SNACKBAR,
  SET_ALL_NODE_DETAILS,
  CLEAR_TOOL_TIP_DATA,
  GET_NODES_LIST,
  SET_LOADER,
  DROPBOX,
  ONEDRIVE,
  NTFS
} from "../../redux/constants/constants";
import { cubeQueryGen, frameTooltipData, getTimeForToolTip, renderSwitchableTabs, sourceIcons } from "../common/utilities";
import AlertBadge from "./Badge/AlertBadge";
import SensitivityBadge from "./Badge/SensitivityBadge";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import { getCubejsApi } from "../../../src/components/common/Dashboard/cubejs-util";
import { isCloudFileSystem, RenderSwitchableTabs } from "../common/utilities";

export default function FileList(props) {
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState(0);
  const fullScreenTabs = ["Sensitive Data Fields", "All Fields"];
  const cloudFullScreenTabs = ["Sensitive Files", "All Files"];
  const [viewTab, setViewTab] = useState("tableView");
  const allFieldsData = useSelector(
    (state) => state.InspectContentReducer.allFieldsData
  );
  const totalPublicFileCount = useSelector(
    (state) => state.InspectContentReducer.totalPublicFileCount
  );
  const publicSensitiveFieldsData = useSelector(
    (state) => state.InspectContentReducer.publicSensitiveFieldsData
  );
  const totalSensitivePublicFileCount = useSelector(
    (state) => state.InspectContentReducer.totalPublicSensitiveFileCount
  );
  const publicFieldsData = useSelector(
    (state) => state.InspectContentReducer.publicFieldsData
  );
  const sensitiveFieldData = useSelector(
    (state) => state.InspectContentReducer.sensitiveDataFields
  );
  const tabView = useSelector(
    (state) => state.InspectContentReducer.tabView
  );
  const loader = useSelector(
    (state) => state.InspectContentReducer.loader
  );
  const totalFilesCount = useSelector(
    (state) => state.InspectContentReducer.totalFileCount
  );
  const sensitiveTotalCount = useSelector(
    (state) => state.InspectContentReducer.sensitiveTotalCount
  );
  const sourceName = useSelector(
    (state) => state.InspectContentReducer.selectedNodeDetails.sourceName
  );
  const nodeTooltipDetails = useSelector(
    (state) => state.InspectContentReducer.toolTipData
  );
  const currentPage = useSelector(
    (state) => state.InspectContentReducer.currentPage
  );
  const pageSize = useSelector(
    (state) => state.InspectContentReducer.pageSize
  );
  const selectedNode = useSelector(
    (state) => state.InspectContentReducer.selectedNodeDetails
  );
  const filters = useSelector(
    (state) => state.InspectContentReducer.filters
  );
  const graphNodesList = useSelector((state) => 
    state.dataMapReducer.graphNodesList
  );
  const nodeTooltip = frameTooltipData(nodeTooltipDetails);
  const nodes = graphNodesList.data.nodes;
  const [selectedDataSource, setSelectedDataSource] = useState('');
  const [changeSource, setChangeSource] = useState(false);
  const cubejsApi = getCubejsApi();
  useEffect(() => {
    const dimensionType = ['nodeId', 'nodeName', 'nodeType'];
    const queryString = cubeQueryGen('NodesOfApplication', dimensionType, []);
    dispatch({
      type: SET_LOADER,
      payload: true,
    });
    //To get NodeIds from Cube Js Api
    dispatch({
      type: GET_NODES_LIST,
      payload: {
        cubejsApi: cubejsApi,
        queryString: queryString,
      },
    });
    if (!isEmpty(selectedNode)){
      setSelectedDataSource(
        {id: selectedNode.node, label : selectedNode.name
      });
    }
    if (tabView) {
      setTabValue(tabView);
    } else {
      setTabValue(0);
    }
  }, []);
  const renderSelectedNodeDetails = (node) => {
    dispatch({
      type: SET_ALL_NODE_DETAILS,
      payload: {
        node: node.id,
        subtype: node.subType,
        name: node.label,
        creationTime: node.creationTime,
        sourceName: node.subType,
      },
    });
  }
  // While reloading file list, set first node as selected node
  useEffect(() => {
    if (isEmpty(selectedNode) && !isEmpty(nodes)) {
      const node = nodes[0];
      renderSelectedNodeDetails(node);
      setSelectedDataSource(nodes[0]);
    }
  }, [nodes]);
  const sourceType = sourceName ? sourceName.code : null;
  const cloudFileSystem = isCloudFileSystem(sourceType) || sourceType === NTFS;

  useEffect(() => {
    if (!isEmpty(selectedNode) && viewTab === "tableView" && !isEmpty(selectedDataSource)) {
      dispatch({
        type: "SELECTED_NODE",
        payload: { node: selectedNode.node, subtype: selectedNode.subtype },
      });
      // To get data for alert badge and sensitivity badge
      dispatch({
        type: 'GET_DATAMAP_TOOLTIP_DATA',
        payload: selectedNode.node,
      });
      if (tabValue === 0) {
        dispatch({ type: FETCH_SENSITIVE_DATA_FIELDS_REQUEST });
      } else if ( tabValue === 1) {
        dispatch({ type: FETCH_ALL_FIELDS_DATA_REQUEST });
      }
    }
    return () => {
      const currentRoute = window.location.pathname;
      if (currentRoute !== ALL_FILES_LIST) {
        dispatch({
          type: CLEAR_ALL_NODE_ID
        });
        dispatch({ type: CLEAR_TOOL_TIP_DATA})
        dispatch({ type: CLEAR_INSPECT_FILTER_DATA });
        dispatch({type: CLOSE_SNACKBAR});
        dispatch({ type: "SELECTED_NODE", payload: { node: "", subtype: "" } });
      }
    }
  }, [viewTab, currentPage, pageSize, selectedDataSource, tabValue]);


  const badges = () => {
    if (!isEmpty(sourceName) && !isEmpty(nodeTooltip) && sourceName.type.code === "APPLICATION") {
      return (
        <div className="inspect-alerts">
          <AlertBadge title={"Alerts:"} parentprops={nodeTooltip.alertToolTipData} InspectContent={true} />
        </div>
      );
    } else if (!isEmpty(nodeTooltip)) {
      return (
        <>
          <div className="inspect-alerts sensitivity">
            <SensitivityBadge parentprops={nodeTooltip.sensitiveData} loc="InspectContent" title={"Sensitivity:"} />
          </div>
          <div className="inspect-alerts">
            <AlertBadge title={"Alerts:"} parentprops={nodeTooltip.alertToolTipData} InspectContent={true} />
          </div>
        </>
      );
    } else {
      return null;
    }
  };

  const disableFilter = () => {
    if(tabValue === 0) {
      return isEmpty(sensitiveFieldData.data) && isEmpty(filters);
    } else {
      return isEmpty(allFieldsData.data) && isEmpty(filters) || viewTab === "heatMapView";
    }
  }
  

  const renderHeader = () => {
    return (
      <div className="inspect-title-header">
        <div className="inspect-title all-files-list">
          <img className="inspect-oneDriveImage" src={sourceIcons(sourceName)} alt="DataSource Icon" />
          <p className="node-title oneDriveNodeTitle">{selectedNode.name}</p>
        </div>
        <div className="inspect-title-right">
          <div className="inspect-dataSource">
            <div className="inspect-text dataOwnerTitles">Data Source: </div>
            <div className="inspect-text">{!isEmpty(sourceName) && sourceName.value ? sourceName.value : "No Data"}</div>
          </div>
          <div className="inspect-creationTime">
            <div className="inspect-text dataOwnerTitles">Added: </div>
            <div className="inspect-text">{getTimeForToolTip(selectedNode.creationTime)}</div>
          </div>
          {badges()}
        </div>
      </div>
    );
  }

  const getFieldDataAndTotalCount = () => {
    let dataObject = {};
    if (tabValue === 0) {
      if (viewTab === "publicFiles") {
        dataObject.fieldData = publicSensitiveFieldsData;
        dataObject.fieldTotalCount = totalSensitivePublicFileCount;
      } else {
        dataObject.fieldData = sensitiveFieldData;
        dataObject.fieldTotalCount = sensitiveTotalCount;
      }
    } else if (tabValue === 1) {
      if (viewTab === "publicFiles") {
        dataObject.fieldData = publicFieldsData;
        dataObject.fieldTotalCount = totalPublicFileCount;
      } else {
        dataObject.fieldData = allFieldsData;
        dataObject.fieldTotalCount = totalFilesCount;
      }
    }
  
    return dataObject;
  };


  const allFieldsComponent = () => {
    const { fieldData, fieldTotalCount } = getFieldDataAndTotalCount();
  
    if (viewTab === "tableView" && !isEmpty(sourceName)) {
      return (
        <>
          <InspectContentTable
            allFilesTableData={fieldData}
            totalCount={fieldTotalCount}
            sourceName={sourceName.code}
            loc="fullScreen"
            loader={loader}
            changeSource={changeSource} />
        </>
      );
    } else if (viewTab === "heatMapView") {
      return (
        <>
          <HeatMap />
        </>
      );
    }  else if (viewTab === "publicFiles") {
          return (
            <>
              <InspectContentTable
                allFilesTableData={fieldData}
                totalCount={fieldTotalCount}
                sourceName={sourceName.code}
                loc="fullScreen"
                loader={loader}
                changeSource={changeSource} />
            </>
          )
        } else { 
          // if public file is inactive load table view again
          return (
            <>
               <InspectContentTable
                allFilesTableData={fieldData}
                totalCount={fieldTotalCount}
                sourceName={sourceName.code}
                loc="fullScreen"
                loader={loader}
                changeSource={changeSource} />
            </>
          );
        }
  }
  


  useEffect(() => {
    if (!loader && changeSource) {
      setChangeSource(false);
    }
  },[loader]);

  const inspectFilter = () => {
    if (!isEmpty(sourceName)) {
      return (
        <InspectFilter
        showFilter={disableFilter()}
        cloudFileSystem={cloudFileSystem}
        value={tabValue}
      />
      );
    }
  }
  const handleSelectedDataSource = (value) => {
    if (!isNull(value)) {
      const node = nodes.find((source) => source.id === value.id);
      renderSelectedNodeDetails(node);
      setViewTab('tableView');
      setSelectedDataSource(value);
      dispatch({type: CLEAR_INSPECT_FILTER_DATA});
      setChangeSource(true);
    }
  };
  const renderDropdown = () => {
    return (
      <Autocomplete
        sx={{ width: 400 }}
        options={nodes}
        value={selectedDataSource}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.label}
          </li>
        )}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={(e, newValue) => handleSelectedDataSource(newValue)}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              label="Select Data Source"
              size="small"
              variant="outlined"
            />
          );
        }}
      />
    );
  };
  const viewSwitch = () => {
    if (!isEmpty(sourceName) && cloudFileSystem) {
      return (
        <InspectViewSwitch
          showFilter={tabValue}
          switchView={setViewTab}
          cloudFileSystem={cloudFileSystem} 
          selectedNode={selectedNode}/>
      );
    } else {
      return null;
    }
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    dispatch({type: CLEAR_INSPECT_FILTER_DATA});
  }

  //Switch B/W Sensitive Files/Fields and All Files/Fields
  const tabData = () => {
    if(cloudFileSystem) {
      return cloudFullScreenTabs
    } else {
      return fullScreenTabs
    }
  }
  const renderFullScreenTabs = () => {
    return (
      <>
        <RenderSwitchableTabs
          tabs={tabData()}
          tabValue={tabValue}
          handleTabChange={handleTabChange}
          />
      </>
    ) 
  }
  return (
    <>
      {loader && (
        <div className="global-loader-holder">
          <img
          src={Page_Loader}
          alt="loading"
          className="loader"
        ></img>
        </div>
      )}
      <div className="fs-file-list-container">
        {renderHeader()}
        <hr className="separatorStyle"></hr>
        <div className="file-list">
          {renderDropdown()}
          {renderFullScreenTabs()}
          <div className="file-list-items">
            {inspectFilter()}
            {viewSwitch()}
          </div>
        </div>
        {allFieldsComponent()}
      </div>
    </>
  );
}

