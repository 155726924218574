import React from 'react';
import ChartWrapper from '../Chart';
import { renderNoData } from '../utils';
import { Link } from 'react-router-dom';
import { handleLowPercentage, pathWithPermission, percentageCalculation, totalCount } from '../../utilities';
import { viewDataMap } from '../../../../redux/constants/constants';
import { generateHeatmapData } from '../utils';
import { HeatMapGrid } from "react-grid-heatmap";
import { startCase, camelCase } from 'lodash';

const heatmap = { rows: 7, columns: 3 };
export function usersHeatMap(cubejsApi, name, query, renderToggler, dashboardWidget) {
  const returnRedirectTo = (name) => {
    let data = {
      "Public Data Assets Distribution": {
        pathname: "/tenant/dataMap",
        permission: [viewDataMap],
      },
    }
    return pathWithPermission(data[name])
  };
  const renderChart = (resultSet) => {
    let heatmapData, heatmapDetails;
    const data = resultSet.loadResponse.results[0].data;
    if (!data || data.length === 0) {
      return renderNoData('30%');
    } else {
      const return_obj = generateHeatmapData(data, heatmap, 'FilesystemHeatmap.fileCount', 'FilesystemHeatmap.username');
      heatmapData = return_obj.heatmapData;
      heatmapDetails = return_obj.heatmapDetails;
      return (
        <React.Fragment>
          <div className="heatMapContainer">
            <HeatMapGrid
              data={heatmapData}
              cellRender={(x, y, value) => (
                <div className="heatmap-tooltip" title={`${startCase(camelCase(heatmapDetails[x][y].name))} has ${heatmapDetails[x][y].alertCount} publicly accessible files `}>{heatmapDetails[x][y].abbr} <span className='heatmap-number'>{'(' + heatmapDetails[x][y].alertCount + ')'}</span></div>
              )}
              cellStyle={(x, y, ratio) => ({
                background: heatmapDetails[x][y].bgColor,
                fontSize: "1.5rem",
                color: `#FFFFFF`
              })}
              cellHeight="3rem"
              xLabelsPos="bottom"
            />
          </div>
        </React.Fragment>
      );
    }
  }

  return (
    <ChartWrapper
      cubejsApi={cubejsApi}
      title={name}
      query={query}
      render={renderChart}
      redirectTo={returnRedirectTo(name)}
      renderToggler={renderToggler}
      dashboardWidget={dashboardWidget}
    />
  )
}